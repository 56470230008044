import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./screens/routes').then(m => m.screenRoutes),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
