import { Injectable, inject } from '@angular/core';
import {
  EbcpMaestroEditInput,
  EbcpMaestroStateInput,
  EbcpMaestroStateQuery,
  MaestroExportMutation,
  QueryInput,
  SetEbcpMaestroEditMutation,
  SetEbcpMaestroStateMutation,
} from '../../API.service';
import { MutationResponse, QueryError, QueryResponse } from '../../core';
import { graphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class BCPService {
  service = inject(graphqlService);

  public async get(
    input: QueryInput
  ): Promise<QueryResponse<EbcpMaestroStateQuery> | Array<QueryError>> {
    const statement = `query EbcpMaestroState($input: QueryInput) {
        ebcpMaestroState(input: $input) {
          __typename
          state
          edit_state
          current_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
          edit_state_change {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
          previous_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
          data_timestamps {
            __typename
            latest_data_import
            latest_data_export
          }
        }
      }`;

    return await this.service.executeQuery(
      statement,
      input,
      'ebcpMaestroState'
    );
  }

  public async updateState(input: EbcpMaestroStateInput) {
    const statement = `mutation SetEbcpMaestroState($input: EbcpMaestroStateInput) {
        setEbcpMaestroState(input: $input) {
          __typename
          success
          detail
          state
          edit_state
          actioned_by
          modified_at
          data_timestamps {
            __typename
            latest_data_import
            latest_data_export
          }
          previous_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
        }
      }`;

    return await this.service.executeMutation<
      MutationResponse<SetEbcpMaestroStateMutation>
    >(statement, { input }, 'setEbcpMaestroState');
  }

  public async updateEdit(input?: EbcpMaestroEditInput) {
    const statement = `mutation SetEbcpMaestroEdit($input: EbcpMaestroEditInput) {
      setEbcpMaestroEdit(input: $input) {
        __typename
        success
        detail
        state
        edit_state
        actioned_by
        modified_at
        data_timestamps {
          __typename
          latest_data_import
          latest_data_export
        }
        previous_state {
          __typename
          success
          detail
          state
          edit_state
          actioned_by
          modified_at
          data_timestamps {
            __typename
            latest_data_import
            latest_data_export
          }
          previous_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
            }
          }
        }
      }
    }`;

    return await this.service.executeMutation<
      MutationResponse<SetEbcpMaestroEditMutation>
    >(statement, { input }, 'setEbcpMaestroEdit');
  }

  public async bcpExport() {
    const statement = `mutation MaestroExport($input: MaestroExportInput) {
      maestroExport(input: $input) {
        __typename
        StatusCode
      }
    }`;

    return await this.service.executeMutation<
      MutationResponse<MaestroExportMutation>
    >(statement, {}, 'maestroExport', true);
  }
}
