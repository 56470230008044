import { Injectable, computed, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  CognitoIdentityProviderClient,
  RevokeTokenCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { Amplify } from 'aws-amplify';
import * as authClient from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import { AppStatusService } from './app-status.service';

export abstract class AbstractAuthService {
  abstract canActivateHomeRoute(): Promise<boolean>;
  abstract canActivateAdminRoute(): Promise<boolean>;
  abstract signInWithUserName(
    username: string,
    password: string
  ): Promise<boolean>;
  abstract sigIn(): Promise<boolean>;
  abstract signOut(): Promise<void>;
  abstract isAdmin(): Promise<boolean>;
  abstract handleOAuthCallback(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthService extends AbstractAuthService {
  appStatus = inject(AppStatusService);
  router = inject(Router);
  snackBar = inject(MatSnackBar);

  isAdmin = computed(async () => {
    try {
      const { tokens } = await authClient.fetchAuthSession({
        forceRefresh: true,
      });
      const idToken = tokens?.idToken;

      if (!idToken) return false;
      const decodedToken = this.decodeJwtToken(
        idToken ? idToken.toString() : ''
      );
      const groups = decodedToken['cognito:groups'] || ([] as string[]);

      const admin_groups = environment.admin_groups;
      const isAdmin = admin_groups.some(g => groups.includes(g));
      this.appStatus.IsAdminLoggedIn.set(isAdmin);
      return isAdmin;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.snackBar.open(error.message || error, 'OK');
      return false;
    }
  });

  constructor() {
    super();

    if (
      environment.environment === 'uat' ||
      environment.environment === 'production'
    ) {
      Amplify.configure({
        Auth: {
          Cognito: {
            ...environment.cognitoAuth,
            loginWith: {
              oauth: {
                domain: environment.cognitoAuth.oauth.domain,
                redirectSignIn: [environment.cognitoAuth.oauth.redirectSignIn],
                redirectSignOut: [
                  environment.cognitoAuth.oauth.redirectSignOut,
                ],
                scopes: [...environment.cognitoAuth.oauth.scope],
                responseType: 'code',
              },
            },
          },
        },
      });
    }
  }

  public async canActivateHomeRoute() {
    if (this.appStatus.IsUserLoggedIn()) {
      return true;
    } else {
      try {
        await authClient.getCurrentUser();

        this.appStatus.IsUserLoggedIn.set(true);
        return true;
      } catch (error) {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }
  public async canActivateAdminRoute() {
    if (this.appStatus.IsAdminLoggedIn()) {
      return true;
    } else {
      try {
        await this.isAdmin();
        this.appStatus.IsUserLoggedIn.set(true);
        return true;
      } catch (error) {
        this.router.navigate(['/login']);
        return false;
      }
    }
  }

  public async signInWithUserName(email: string, password: string) {
    try {
      const signInObj = {
        username: email,
        password: password,
      };
      const user = await authClient.signIn(signInObj);
      if (user && user.isSignedIn) {
        this.appStatus.IsUserLoggedIn.set(true);
        return true;
      } else if (
        user.nextStep.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        const { isSignedIn } = await authClient.confirmSignIn({
          challengeResponse: password,
          options: {},
        });
        if (isSignedIn) {
          this.appStatus.IsUserLoggedIn.set(true);
          return true;
        } else return false;
      }
      return false;
    } catch (error) {
      if (error instanceof Error) {
        this.snackBar.open(error.message, 'Close', {
          duration: 3000, // Display for 3 seconds
        });
      }
      return false;
    }
  }

  async sigIn(): Promise<boolean> {
    try {
      Amplify.configure({
        Auth: {
          Cognito: {
            ...environment.cognitoAuth,
            loginWith: {
              oauth: {
                domain: environment.cognitoAuth.oauth.domain,
                redirectSignIn: [environment.cognitoAuth.oauth.redirectSignIn],
                redirectSignOut: [
                  environment.cognitoAuth.oauth.redirectSignOut,
                ],
                scopes: [...environment.cognitoAuth.oauth.scope],
                responseType: 'code',
              },
            },
          },
        },
      });
      /*  FEDERATED LOGIN*/
      const user = await authClient.signInWithRedirect({
        provider: {
          custom: 'SocialProvider',
        },
      });

      if (user != null) {
        this.appStatus.IsUserLoggedIn.set(true);
        return true;
      }

      return false;
    } catch (error) {
      if (error instanceof Error) {
        this.snackBar.open(error.message, 'Close', {
          duration: 3000, // Display for 3 seconds
        });
      }
      return false;
    }
  }

  async signOut() {
    await this.revokeToken();
    await authClient.signOut({ global: true });
    this.appStatus.IsUserLoggedIn.set(false);
    this.appStatus.IsAdminLoggedIn.set(false);
    this.router.navigate(['/login']);
  }

  async revokeToken() {
    const { tokens } = await authClient.fetchAuthSession({
      forceRefresh: true,
    });
    if (tokens) {
      const idToken = tokens?.idToken;
      const decodedToken = this.decodeJwtToken(
        idToken ? idToken.toString() : ''
      );
      if (decodedToken?.jti) {
        const input = {
          // RevokeTokenRequest
          Token: decodedToken.jti, // required
          ClientId: environment.cognitoAuth.userPoolClientId, // required
          ///ClientSecret: "STRING_VALUE",
        };
        const command = new RevokeTokenCommand(input);
        const client = new CognitoIdentityProviderClient({
          ...environment.cognitoAuth,
        });
        await client.send(command);
      }
    }
  }

  private decodeJwtToken(token: string) {
    const payload = token.split('.')[1];
    return JSON.parse(window.atob(payload));
  }

  async handleOAuthCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code'); // Or id_token, depending on the flow
    if (code) {
      try {
        Amplify.configure({
          Auth: {
            Cognito: {
              ...environment.cognitoAuth,
            },
          },
        });
        // Now retrieve the session
        const session = await authClient.fetchAuthSession(); //.currentSession();
        // Update application state based on successful authentication
        if (session) this.appStatus.IsUserLoggedIn.set(true);
      } catch (error) {
        console.error('Error completing OAuth callback:', error);
        // Handle error, possibly redirect to an error page or login page
      }
    }
  }
}
