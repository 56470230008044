import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { QueryError } from '../../core';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.errors) {
            // Assuming the structure of the response
            const graphqlErrors = event.body.errors;
            graphqlErrors?.forEach((err: QueryError) => {
              this.snackBar.open(err.message, 'Ok', {
                duration: 3000,
              });
            });
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // Handle HTTP errors here if needed
        return throwError(error);
      })
    );
  }
}
