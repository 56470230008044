export const environment = {
  environment: 'uat',
  clientId: '',
  authority: '',
  redirectUri: '',
  cognitoAuth: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_CrwYnS3zw',
    userPoolWebClientId: '5tgkcj9peo0p7kaan99g9ghd5l',
    userPoolClientId: '5tgkcj9peo0p7kaan99g9ghd5l',
    oauth: {
      domain: 'prl-ba.auth.eu-west-2.amazoncognito.com', // Your Cognito domain
      scope: ['openid', 'email', 'profile'],
      // redirectSignIn: 'https://main.d1sm3kztgn9mh1.amplifyapp.com', // Ensure this matches the callback URL configured in Cognito and Azure AD
      // redirectSignOut: 'https://main.d1sm3kztgn9mh1.amplifyapp.com', // Ensure this matches the sign-out URL configured in Cognito and Azure AD
      redirectSignIn: 'https://www.prl-ebcp.ba.com',
      redirectSignOut: 'https://www.prl-ebcp.ba.com',
      responseType: 'code', // Using Authorization Code Grant Flow
    },
  },
  awsmobile: {
    aws_project_region: 'eu-west-2',
    aws_appsync_graphqlEndpoint:
      'https://5myq55oqwzfh5nlaetrdchinhe.appsync-api.eu-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_user_pools_id: 'eu-west-2_CrwYnS3zw',
    aws_cognito_region: 'eu-west-2',
    aws_user_pools_web_client_id: '5tgkcj9peo0p7kaan99g9ghd5l',
  },
  admin_groups: ['ebcp_admins_prl'],
  pollingInterval: 5000,
};
