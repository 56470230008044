/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from '@angular/core';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { Amplify } from 'aws-amplify';
import { generateClient, GraphQLResult } from 'aws-amplify/api';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

Amplify.configure({ ...environment.awsmobile});
const client = generateClient();

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  OnCreatedCancelledFlights: OnCreatedCancelledFlightsSubscription;
  OnUpdatedCancelledFlight: OnUpdatedCancelledFlightSubscription;
  OnDeletedCancelledFlight: OnDeletedCancelledFlightSubscription;
};

export type CrewRosterID = {
  roster_id?: string | null;
  restriction_id?: string | null;
};

export type CrewRosterInput = {
  cmname: string;
  cmid: number;
  flight_no?: number | null;
  carrier?: string | null;
  suffix?: string | null;
  leg_sdate?: string | null;
  sloc?: string | null;
  phone: string;
  lastname?: string | null;
  firstname?: string | null;
  init?: string | null;
  base?: string | null;
  fleet?: number | null;
  seat?: string | null;
  qualtype?: string | null;
  report?: string | null;
  eloc?: string | null;
  leg_edate?: string | null;
  release?: string | null;
  leg_blocktime?: string | null;
  is_heavy?: boolean | null;
  acreg?: string | null;
  trip_tafb?: number | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  pos?: string | null;
  credit?: number | null;
  label?: string | null;
  nfcode?: string | null;
  tripno?: string | null;
  dhd?: boolean | null;
  etd?: string | null;
  eta?: string | null;
  out?: string | null;
  off?: string | null;
  _on?: string | null;
  _in?: string | null;
  rca?: number | null;
  rfo?: number | null;
  xed?: string | null;
  seniority?: number | null;
  linetype?: string | null;
  subtype?: string | null;
  date?: string | null;
  expire?: string | null;
  comments?: string | null;
};

export type MutationResponse = {
  __typename: 'MutationResponse';
  result?: Record | null;
  status: MutationStatus;
  detail?: string | null;
};

export type Record =
  | CrewRoster
  | CrewRestriction
  | OpenAssignments
  | AssignableCrew
  | CancelledFlights
  | OperatingFlights;

export type CrewRoster = {
  __typename: 'CrewRoster';
  roster_id?: string | null;
  restriction_id?: string | null;
  cmname?: string | null;
  cmid?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  init?: string | null;
  base?: string | null;
  fleet?: string | null;
  seat?: string | null;
  phone?: string | null;
  qualtype?: string | null;
  report?: string | null;
  carrier?: string | null;
  flight_no?: string | null;
  sloc?: string | null;
  leg_sdate?: string | null;
  eloc?: string | null;
  leg_edate?: string | null;
  release?: string | null;
  leg_blocktime?: string | null;
  is_heavy?: boolean | null;
  acreg?: string | null;
  trip_tafb?: number | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  pos?: string | null;
  credit?: number | null;
  label?: string | null;
  nfcode?: string | null;
  tripno?: string | null;
  dhd?: boolean | null;
  suffix?: string | null;
  etd?: string | null;
  eta?: string | null;
  out?: string | null;
  off?: string | null;
  _on?: string | null;
  _in?: string | null;
  rca?: number | null;
  rfo?: number | null;
  xed?: string | null;
  seniority?: number | null;
  linetype?: string | null;
  subtype?: string | null;
  date?: string | null;
  expire?: string | null;
  comments?: string | null;
  modified_at?: string | null;
  changed_by?: string | null;
  deleted?: boolean | null;
};

export type CrewRestriction = {
  __typename: 'CrewRestriction';
  restriction_id?: string | null;
  cmname?: string | null;
  cmid?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  init?: string | null;
  base?: string | null;
  fleet?: string | null;
  seat?: string | null;
  seniority?: number | null;
  linetype?: string | null;
  phone?: string | null;
  qualtype?: string | null;
  subtype?: string | null;
  date?: string | null;
  expire?: string | null;
  comments?: string | null;
  modified_at?: string | null;
  changed_by?: string | null;
  deleted?: boolean | null;
};

export type OpenAssignments = {
  __typename: 'OpenAssignments';
  open_assignment_id?: string | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  fleet?: string | null;
  pos?: string | null;
  tripdate?: string | null;
  tripno?: string | null;
  report?: string | null;
  carrier?: string | null;
  sloc?: string | null;
  flight_no?: number | null;
  suffix?: string | null;
  leg_sdate?: string | null;
  eloc?: string | null;
  leg_edate?: string | null;
  release?: string | null;
  leg_blocktime?: string | null;
  is_heavy?: boolean | null;
  isdhd?: boolean | null;
  acreg?: string | null;
  credit?: number | null;
  etd?: string | null;
  eta?: string | null;
  rca?: number | null;
  rfo?: number | null;
  xed?: string | null;
  trip_tafb?: number | null;
  modified_at?: string | null;
  changed_by?: string | null;
  deleted?: boolean | null;
  status?: ActionStatus | null;
};

export enum ActionStatus {
  TO_ACTION = 'TO_ACTION',
  IN_PROGRESS = 'IN_PROGRESS',
  ACTIONED = 'ACTIONED',
}

export enum FileName {
  AssignableCrew = 'Assignable Crew',
  CancelledFlights = 'Cancelled Flights',
  OperatingFlights = 'Operating Flights',
  OpenAssignments = 'Open Assignments',
  CrewRoster = 'Crew Roster',
}

export type AssignableCrew = {
  __typename: 'AssignableCrew';
  roster_id?: string | null;
  restriction_id?: string | null;
  cmname?: string | null;
  cmid?: string | null;
  base?: string | null;
  fleet?: string | null;
  seat?: string | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  pos?: string | null;
  nfcode?: string | null;
  qualtype?: string | null;
  credit?: number | null;
  seniority?: number | null;
  linetype?: string | null;
  phone?: string | null;
  modified_at?: string | null;
  changed_by?: string | null;
  deleted?: boolean | null;
};

export type CancelledFlights = {
  __typename: 'CancelledFlights';
  cancelled_id?: string | null;
  flight_type?: FlightType | null;
  flight_no?: string | null;
  carrier?: string | null;
  leg_sdate?: string | null;
  leg_edate?: string | null;
  leg_blocktime?: string | null;
  sloc?: string | null;
  eloc?: string | null;
  etd?: string | null;
  eta?: string | null;
  acreg?: string | null;
  fleet?: string | null;
  tripno?: string | null;
  tripdate?: string | null;
  crewnames?: string | null;
  suffix?: string | null;
  out?: string | null;
  off?: string | null;
  _on?: string | null;
  _in?: string | null;
  tripxld?: string | null;
  prevflt?: string | null;
  nextflt?: string | null;
  modified_at?: string | null;
  changed_by?: string | null;
  deleted?: boolean | null;
  status?: ActionStatus | null;
};

export enum FlightType {
  LH = 'LH',
  SH = 'SH',
}

export type OperatingFlights = {
  __typename: 'OperatingFlights';
  operating_id?: string | null;
  flight_type?: FlightType | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  fleet?: string | null;
  cmname?: string | null;
  cmid?: string | null;
  tripno?: string | null;
  report?: string | null;
  carrier?: string | null;
  flight_no?: number | null;
  sloc?: string | null;
  leg_sdate?: string | null;
  eloc?: string | null;
  leg_edate?: string | null;
  release?: string | null;
  leg_blocktime?: string | null;
  rca?: number | null;
  rfo?: number | null;
  acreg?: string | null;
  pos?: string | null;
  credit?: number | null;
  dhd?: boolean | null;
  is_heavy?: boolean | null;
  suffix?: string | null;
  etd?: string | null;
  eta?: string | null;
  out?: string | null;
  off?: string | null;
  _on?: string | null;
  _in?: string | null;
  xed?: string | null;
  trip_tafb?: number | null;
  modified_at?: string | null;
  changed_by?: string | null;
  deleted?: boolean | null;
};

export enum MutationStatus {
  UNKNOWN = 'UNKNOWN',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  RESTORED = 'RESTORED',
  FAILURE = 'FAILURE',
}

export enum DeleteOperation {
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
}

export type CrewRestrictionID = {
  restriction_id?: string | null;
};

export type CrewRestrictionInput = {
  cmname: string;
  cmid: number;
  phone: string;
  lastname?: string | null;
  firstname?: string | null;
  init?: string | null;
  base?: string | null;
  fleet?: number | null;
  seat?: string | null;
  seniority?: number | null;
  linetype?: string | null;
  qualtype?: string | null;
  subtype?: string | null;
  date?: string | null;
  expire?: string | null;
  comments?: string | null;
};

export type OpenAssignmentsID = {
  open_assignment_id?: string | null;
};

export type OpenAssignmentsInput = {
  flight_no?: number | null;
  carrier?: string | null;
  suffix?: string | null;
  leg_sdate?: string | null;
  sloc?: string | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  fleet?: number | null;
  pos?: string | null;
  tripdate?: string | null;
  tripno?: number | null;
  report?: string | null;
  eloc?: string | null;
  leg_edate?: string | null;
  release?: string | null;
  leg_blocktime?: string | null;
  is_heavy?: boolean | null;
  isdhd?: boolean | null;
  acreg?: string | null;
  credit?: number | null;
  etd?: string | null;
  eta?: string | null;
  rca?: number | null;
  rfo?: number | null;
  xed?: string | null;
  trip_tafb?: number | null;
  status?: ActionStatus | null;
};

export type AssignableCrewID = {
  roster_id?: string | null;
  restriction_id?: string | null;
};

export type AssignableCrewInput = {
  cmname: string;
  cmid: number;
  phone: string;
  roster_id?: string | null;
  restriction_id?: string | null;
  base?: string | null;
  fleet?: number | null;
  seat?: string | null;
  asmtbegin?: string | null;
  asmtend?: string | null;
  pos?: string | null;
  nfcode?: string | null;
  qualtype?: string | null;
  credit?: number | null;
  seniority?: number | null;
  linetype?: string | null;
};

export type CancelledFlightsID = {
  cancelled_id?: string | null;
};

export type CancelledFlightsInput = {
  flight_type: FlightType;
  flight_no?: number | null;
  carrier: string;
  suffix?: string | null;
  leg_sdate: string;
  sloc: string;
  leg_edate?: string | null;
  leg_blocktime?: string | null;
  eloc?: string | null;
  etd?: string | null;
  eta?: string | null;
  acreg?: string | null;
  fleet?: number | null;
  tripno?: number | null;
  tripdate?: string | null;
  crewnames?: string | null;
  out?: string | null;
  off?: string | null;
  _on?: string | null;
  _in?: string | null;
  tripxld?: string | null;
  prevflt?: string | null;
  nextflt?: string | null;
  status?: ActionStatus | null;
};

export type OperatingFlightsID = {
  operating_id?: string | null;
};

export type OperatingFlightsInput = {
  flight_type: FlightType;
  asmtbegin: string;
  asmtend: string;
  flight_no: number;
  carrier: string;
  suffix?: string | null;
  leg_sdate: string;
  sloc: string;
  fleet?: number | null;
  cmname?: string | null;
  cmid?: number | null;
  tripno?: number | null;
  report?: string | null;
  eloc?: string | null;
  leg_edate?: string | null;
  release?: string | null;
  leg_blocktime?: string | null;
  rca?: number | null;
  rfo?: number | null;
  acreg?: string | null;
  pos?: string | null;
  credit?: number | null;
  dhd?: boolean | null;
  is_heavy?: boolean | null;
  etd?: string | null;
  eta?: string | null;
  out?: string | null;
  off?: string | null;
  _on?: string | null;
  _in?: string | null;
  xed?: string | null;
  trip_tafb?: number | null;
};

export type EbcpMaestroStateInput = {
  state: PlatformState;
};

export enum PlatformState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type EbcpMaestroStateResponse = {
  __typename: 'EbcpMaestroStateResponse';
  success?: boolean | null;
  detail?: string | null;
  state?: PlatformState | null;
  edit_state?: EditState | null;
  actioned_by?: string | null;
  modified_at?: string | null;
  data_timestamps?: EbcpMaestroDataState | null;
  previous_state?: EbcpMaestroStateResponse | null;
};

export enum EditState {
  EDIT_ON = 'EDIT_ON',
  EDIT_OFF = 'EDIT_OFF',
}

export type EbcpMaestroDataState = {
  __typename: 'EbcpMaestroDataState';
  latest_data_import?: string | null;
  latest_data_export?: string | null;
};

export type EbcpMaestroEditInput = {
  edit_state: EditState;
};

export type MaestroExportInput = {
  export?: boolean | null;
};

export type MaestroExportMutation = {
  __typename: "MaestroExportResponse";
  StatusCode?: number | null;
};

export type MaestroExportResponse = {
  __typename: "MaestroExportResponse";
  StatusCode?: number | null;
};

export type QueryInput = {
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  offset?: number | null;
  limit?: number | null;
  filters?: Array<FilterInput> | null;
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FilterInput = {
  key: string;
  filterType: FilterType;
  stringValues?: Array<string> | null;
  floatValues?: Array<number> | null;
  datetimeValues?: Array<string> | null;
};

export enum FilterType {
  CONTAINS_STRING = 'CONTAINS_STRING',
  NOT_CONTAINS_STRING = 'NOT_CONTAINS_STRING',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
}

export type CrewRostersResults = {
  __typename: 'CrewRostersResults';
  results: Array<CrewRoster>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type CrewRestrictionsResults = {
  __typename: 'CrewRestrictionsResults';
  results: Array<CrewRestriction>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type OpenAssignmentsResults = {
  __typename: 'OpenAssignmentsResults';
  results: Array<OpenAssignments>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type AssignableCrewResults = {
  __typename: 'AssignableCrewResults';
  results: Array<AssignableCrew>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type CancelledFlightsResults = {
  __typename: 'CancelledFlightsResults';
  results: Array<CancelledFlights>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type OperatingFlightsResults = {
  __typename: 'OperatingFlightsResults';
  results: Array<OperatingFlights>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type AuditResults = {
  __typename: 'AuditResults';
  results: Array<Audit>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type Audit = {
  __typename: 'Audit';
  id: string;
  modified_at?: string | null;
  changed_by?: string | null;
  action?: MutationStatus | null;
  table_name?: string | null;
  record_id?: string | null;
  changes?: Array<AuditChanges | null> | null;
};

export type AuditChanges = {
  __typename: 'AuditChanges';
  field_name?: string | null;
  old_value?: string | null;
  new_value?: string | null;
};

export type EbcpMaestroApplicationState = {
  __typename: 'EbcpMaestroApplicationState';
  state?: PlatformState | null;
  edit_state?: EditState | null;
  current_state?: EbcpMaestroStateResponse | null;
  edit_state_change?: EbcpMaestroStateResponse | null;
  previous_state?: EbcpMaestroStateResponse | null;
  data_timestamps?: EbcpMaestroDataState | null;
};

export type CreateCrewRosterMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type UpdateCrewRosterMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type DeleteCrewRosterMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type CreateCrewRestrictionMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type UpdateCrewRestrictionMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type DeleteCrewRestrictionMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type CreateOpenAssignmentsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type UpdateOpenAssignmentsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type DeleteOpenAssignmentsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type CreateAssignableCrewMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type UpdateAssignableCrewMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type DeleteAssignableCrewMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type CreateCancelledFlightsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type UpdateCancelledFlightsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type DeleteCancelledFlightsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type CreateOperatingFlightsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type UpdateOperatingFlightsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type DeleteOperatingFlightsMutation = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type SetEbcpMaestroStateMutation = {
  __typename: 'EbcpMaestroStateResponse';
  success?: boolean | null;
  detail?: string | null;
  state?: PlatformState | null;
  edit_state?: EditState | null;
  actioned_by?: string | null;
  modified_at?: string | null;
  data_timestamps?: {
    __typename: 'EbcpMaestroDataState';
    latest_data_import?: string | null;
    latest_data_export?: string | null;
  } | null;
  previous_state?: {
    __typename: 'EbcpMaestroStateResponse';
    success?: boolean | null;
    detail?: string | null;
    state?: PlatformState | null;
    edit_state?: EditState | null;
    actioned_by?: string | null;
    modified_at?: string | null;
    data_timestamps?: {
      __typename: 'EbcpMaestroDataState';
      latest_data_import?: string | null;
      latest_data_export?: string | null;
    } | null;
    previous_state?: {
      __typename: 'EbcpMaestroStateResponse';
      success?: boolean | null;
      detail?: string | null;
      state?: PlatformState | null;
      edit_state?: EditState | null;
      actioned_by?: string | null;
      modified_at?: string | null;
      data_timestamps?: {
        __typename: 'EbcpMaestroDataState';
        latest_data_import?: string | null;
        latest_data_export?: string | null;
      } | null;
      previous_state?: {
        __typename: 'EbcpMaestroStateResponse';
        success?: boolean | null;
        detail?: string | null;
        state?: PlatformState | null;
        edit_state?: EditState | null;
        actioned_by?: string | null;
        modified_at?: string | null;
      } | null;
    } | null;
  } | null;
};

export type SetEbcpMaestroEditMutation = {
  __typename: 'EbcpMaestroStateResponse';
  success?: boolean | null;
  detail?: string | null;
  state?: PlatformState | null;
  edit_state?: EditState | null;
  actioned_by?: string | null;
  modified_at?: string | null;
  data_timestamps?: {
    __typename: 'EbcpMaestroDataState';
    latest_data_import?: string | null;
    latest_data_export?: string | null;
  } | null;
  previous_state?: {
    __typename: 'EbcpMaestroStateResponse';
    success?: boolean | null;
    detail?: string | null;
    state?: PlatformState | null;
    edit_state?: EditState | null;
    actioned_by?: string | null;
    modified_at?: string | null;
    data_timestamps?: {
      __typename: 'EbcpMaestroDataState';
      latest_data_import?: string | null;
      latest_data_export?: string | null;
    } | null;
    previous_state?: {
      __typename: 'EbcpMaestroStateResponse';
      success?: boolean | null;
      detail?: string | null;
      state?: PlatformState | null;
      edit_state?: EditState | null;
      actioned_by?: string | null;
      modified_at?: string | null;
      data_timestamps?: {
        __typename: 'EbcpMaestroDataState';
        latest_data_import?: string | null;
        latest_data_export?: string | null;
      } | null;
      previous_state?: {
        __typename: 'EbcpMaestroStateResponse';
        success?: boolean | null;
        detail?: string | null;
        state?: PlatformState | null;
        edit_state?: EditState | null;
        actioned_by?: string | null;
        modified_at?: string | null;
      } | null;
    } | null;
  } | null;
};

export type CrewRostersQuery = {
  __typename: 'CrewRostersResults';
  results: Array<{
    __typename: 'CrewRoster';
    roster_id?: string | null;
    restriction_id?: string | null;
    cmname?: string | null;
    cmid?: string | null;
    lastname?: string | null;
    firstname?: string | null;
    init?: string | null;
    base?: string | null;
    fleet?: string | null;
    seat?: string | null;
    phone?: string | null;
    qualtype?: string | null;
    report?: string | null;
    carrier?: string | null;
    flight_no?: string | null;
    sloc?: string | null;
    leg_sdate?: string | null;
    eloc?: string | null;
    leg_edate?: string | null;
    release?: string | null;
    leg_blocktime?: string | null;
    is_heavy?: boolean | null;
    acreg?: string | null;
    trip_tafb?: number | null;
    asmtbegin?: string | null;
    asmtend?: string | null;
    pos?: string | null;
    credit?: number | null;
    label?: string | null;
    nfcode?: string | null;
    tripno?: string | null;
    dhd?: boolean | null;
    suffix?: string | null;
    etd?: string | null;
    eta?: string | null;
    out?: string | null;
    off?: string | null;
    _on?: string | null;
    _in?: string | null;
    rca?: number | null;
    rfo?: number | null;
    xed?: string | null;
    seniority?: number | null;
    linetype?: string | null;
    subtype?: string | null;
    date?: string | null;
    expire?: string | null;
    comments?: string | null;
    modified_at?: string | null;
    changed_by?: string | null;
    deleted?: boolean | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type CrewRestrictionsQuery = {
  __typename: 'CrewRestrictionsResults';
  results: Array<{
    __typename: 'CrewRestriction';
    restriction_id?: string | null;
    cmname?: string | null;
    cmid?: string | null;
    lastname?: string | null;
    firstname?: string | null;
    init?: string | null;
    base?: string | null;
    fleet?: string | null;
    seat?: string | null;
    seniority?: number | null;
    linetype?: string | null;
    phone?: string | null;
    qualtype?: string | null;
    subtype?: string | null;
    date?: string | null;
    expire?: string | null;
    comments?: string | null;
    modified_at?: string | null;
    changed_by?: string | null;
    deleted?: boolean | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type OpenAssignmentsQuery = {
  __typename: 'OpenAssignmentsResults';
  results: Array<{
    __typename: 'OpenAssignments';
    open_assignment_id?: string | null;
    asmtbegin?: string | null;
    asmtend?: string | null;
    fleet?: string | null;
    pos?: string | null;
    tripdate?: string | null;
    tripno?: string | null;
    report?: string | null;
    carrier?: string | null;
    sloc?: string | null;
    flight_no?: string | null;
    suffix?: string | null;
    leg_sdate?: string | null;
    eloc?: string | null;
    leg_edate?: string | null;
    release?: string | null;
    leg_blocktime?: string | null;
    is_heavy?: boolean | null;
    isdhd?: boolean | null;
    acreg?: string | null;
    credit?: number | null;
    etd?: string | null;
    eta?: string | null;
    rca?: number | null;
    rfo?: number | null;
    xed?: string | null;
    trip_tafb?: number | null;
    modified_at?: string | null;
    changed_by?: string | null;
    deleted?: boolean | null;
    status?: ActionStatus | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type AssignableCrewQuery = {
  __typename: 'AssignableCrewResults';
  results: Array<{
    __typename: 'AssignableCrew';
    roster_id?: string | null;
    restriction_id?: string | null;
    cmname?: string | null;
    cmid?: string | null;
    base?: string | null;
    fleet?: string | null;
    seat?: string | null;
    asmtbegin?: string | null;
    asmtend?: string | null;
    pos?: string | null;
    nfcode?: string | null;
    qualtype?: string | null;
    credit?: number | null;
    seniority?: number | null;
    linetype?: string | null;
    phone?: string | null;
    modified_at?: string | null;
    changed_by?: string | null;
    deleted?: boolean | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type CancelledFlightsQuery = {
  __typename: 'CancelledFlightsResults';
  results: Array<{
    __typename: 'CancelledFlights';
    cancelled_id?: string | null;
    flight_type?: FlightType | null;
    flight_no?: string | null;
    carrier?: string | null;
    leg_sdate?: string | null;
    leg_edate?: string | null;
    leg_blocktime?: string | null;
    sloc?: string | null;
    eloc?: string | null;
    etd?: string | null;
    eta?: string | null;
    acreg?: string | null;
    fleet?: string | null;
    tripno?: string | null;
    tripdate?: string | null;
    crewnames?: string | null;
    suffix?: string | null;
    out?: string | null;
    off?: string | null;
    _on?: string | null;
    _in?: string | null;
    tripxld?: string | null;
    prevflt?: string | null;
    nextflt?: string | null;
    modified_at?: string | null;
    changed_by?: string | null;
    deleted?: boolean | null;
    status?: ActionStatus | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type OperatingFlightsQuery = {
  __typename: 'OperatingFlightsResults';
  results: Array<{
    __typename: 'OperatingFlights';
    operating_id?: string | null;
    flight_type?: FlightType | null;
    asmtbegin?: string | null;
    asmtend?: string | null;
    fleet?: string | null;
    cmname?: string | null;
    cmid?: string | null;
    tripno?: string | null;
    report?: string | null;
    carrier?: string | null;
    flight_no?: string | null;
    sloc?: string | null;
    leg_sdate?: string | null;
    eloc?: string | null;
    leg_edate?: string | null;
    release?: string | null;
    leg_blocktime?: string | null;
    rca?: number | null;
    rfo?: number | null;
    acreg?: string | null;
    pos?: string | null;
    credit?: number | null;
    dhd?: boolean | null;
    is_heavy?: boolean | null;
    suffix?: string | null;
    etd?: string | null;
    eta?: string | null;
    out?: string | null;
    off?: string | null;
    _on?: string | null;
    _in?: string | null;
    xed?: string | null;
    trip_tafb?: number | null;
    modified_at?: string | null;
    changed_by?: string | null;
    deleted?: boolean | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type AuditQuery = {
  __typename: 'AuditResults';
  results: Array<{
    __typename: 'Audit';
    id: string;
    modified_at?: string | null;
    changed_by?: string | null;
    action?: MutationStatus | null;
    table_name?: string | null;
    record_id?: string | null;
    changes?: Array<{
      __typename: 'AuditChanges';
      field_name?: string | null;
      old_value?: string | null;
      new_value?: string | null;
    } | null> | null;
  }>;
  sortField?: string | null;
  sortOrder?: SortOrder | null;
  pageSize?: number | null;
  offset?: number | null;
  total?: number | null;
};

export type EbcpMaestroStateQuery = {
  __typename: 'EbcpMaestroApplicationState';
  state?: PlatformState | null;
  edit_state?: EditState | null;
  current_state?: {
    __typename: 'EbcpMaestroStateResponse';
    success?: boolean | null;
    detail?: string | null;
    state?: PlatformState | null;
    edit_state?: EditState | null;
    actioned_by?: string | null;
    modified_at?: string | null;
    data_timestamps?: {
      __typename: 'EbcpMaestroDataState';
      latest_data_import?: string | null;
      latest_data_export?: string | null;
    } | null;
    previous_state?: {
      __typename: 'EbcpMaestroStateResponse';
      success?: boolean | null;
      detail?: string | null;
      state?: PlatformState | null;
      edit_state?: EditState | null;
      actioned_by?: string | null;
      modified_at?: string | null;
      data_timestamps?: {
        __typename: 'EbcpMaestroDataState';
        latest_data_import?: string | null;
        latest_data_export?: string | null;
      } | null;
      previous_state?: {
        __typename: 'EbcpMaestroStateResponse';
        success?: boolean | null;
        detail?: string | null;
        state?: PlatformState | null;
        edit_state?: EditState | null;
        actioned_by?: string | null;
        modified_at?: string | null;
      } | null;
    } | null;
  } | null;
  edit_state_change?: {
    __typename: 'EbcpMaestroStateResponse';
    success?: boolean | null;
    detail?: string | null;
    state?: PlatformState | null;
    edit_state?: EditState | null;
    actioned_by?: string | null;
    modified_at?: string | null;
    data_timestamps?: {
      __typename: 'EbcpMaestroDataState';
      latest_data_import?: string | null;
      latest_data_export?: string | null;
    } | null;
    previous_state?: {
      __typename: 'EbcpMaestroStateResponse';
      success?: boolean | null;
      detail?: string | null;
      state?: PlatformState | null;
      edit_state?: EditState | null;
      actioned_by?: string | null;
      modified_at?: string | null;
      data_timestamps?: {
        __typename: 'EbcpMaestroDataState';
        latest_data_import?: string | null;
        latest_data_export?: string | null;
      } | null;
      previous_state?: {
        __typename: 'EbcpMaestroStateResponse';
        success?: boolean | null;
        detail?: string | null;
        state?: PlatformState | null;
        edit_state?: EditState | null;
        actioned_by?: string | null;
        modified_at?: string | null;
      } | null;
    } | null;
  } | null;
  previous_state?: {
    __typename: 'EbcpMaestroStateResponse';
    success?: boolean | null;
    detail?: string | null;
    state?: PlatformState | null;
    edit_state?: EditState | null;
    actioned_by?: string | null;
    modified_at?: string | null;
    data_timestamps?: {
      __typename: 'EbcpMaestroDataState';
      latest_data_import?: string | null;
      latest_data_export?: string | null;
    } | null;
    previous_state?: {
      __typename: 'EbcpMaestroStateResponse';
      success?: boolean | null;
      detail?: string | null;
      state?: PlatformState | null;
      edit_state?: EditState | null;
      actioned_by?: string | null;
      modified_at?: string | null;
      data_timestamps?: {
        __typename: 'EbcpMaestroDataState';
        latest_data_import?: string | null;
        latest_data_export?: string | null;
      } | null;
      previous_state?: {
        __typename: 'EbcpMaestroStateResponse';
        success?: boolean | null;
        detail?: string | null;
        state?: PlatformState | null;
        edit_state?: EditState | null;
        actioned_by?: string | null;
        modified_at?: string | null;
      } | null;
    } | null;
  } | null;
  data_timestamps?: {
    __typename: 'EbcpMaestroDataState';
    latest_data_import?: string | null;
    latest_data_export?: string | null;
  } | null;
};

export type OnCreatedCancelledFlightsSubscription = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type OnUpdatedCancelledFlightSubscription = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

export type OnDeletedCancelledFlightSubscription = {
  __typename: 'MutationResponse';
  result:
    | (
        | {
            __typename: 'CrewRoster';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            acreg?: string | null;
            trip_tafb?: number | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            credit?: number | null;
            label?: string | null;
            nfcode?: string | null;
            tripno?: string | null;
            dhd?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CrewRestriction';
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            lastname?: string | null;
            firstname?: string | null;
            init?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            qualtype?: string | null;
            subtype?: string | null;
            date?: string | null;
            expire?: string | null;
            comments?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'OpenAssignments';
            open_assignment_id?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            pos?: string | null;
            tripdate?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            sloc?: string | null;
            flight_no?: string | null;
            suffix?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            is_heavy?: boolean | null;
            isdhd?: boolean | null;
            acreg?: string | null;
            credit?: number | null;
            etd?: string | null;
            eta?: string | null;
            rca?: number | null;
            rfo?: number | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'AssignableCrew';
            roster_id?: string | null;
            restriction_id?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            base?: string | null;
            fleet?: string | null;
            seat?: string | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            pos?: string | null;
            nfcode?: string | null;
            qualtype?: string | null;
            credit?: number | null;
            seniority?: number | null;
            linetype?: string | null;
            phone?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
        | {
            __typename: 'CancelledFlights';
            cancelled_id?: string | null;
            flight_type?: FlightType | null;
            flight_no?: string | null;
            carrier?: string | null;
            leg_sdate?: string | null;
            leg_edate?: string | null;
            leg_blocktime?: string | null;
            sloc?: string | null;
            eloc?: string | null;
            etd?: string | null;
            eta?: string | null;
            acreg?: string | null;
            fleet?: string | null;
            tripno?: string | null;
            tripdate?: string | null;
            crewnames?: string | null;
            suffix?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            tripxld?: string | null;
            prevflt?: string | null;
            nextflt?: string | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
            status?: ActionStatus | null;
          }
        | {
            __typename: 'OperatingFlights';
            operating_id?: string | null;
            flight_type?: FlightType | null;
            asmtbegin?: string | null;
            asmtend?: string | null;
            fleet?: string | null;
            cmname?: string | null;
            cmid?: string | null;
            tripno?: string | null;
            report?: string | null;
            carrier?: string | null;
            flight_no?: string | null;
            sloc?: string | null;
            leg_sdate?: string | null;
            eloc?: string | null;
            leg_edate?: string | null;
            release?: string | null;
            leg_blocktime?: string | null;
            rca?: number | null;
            rfo?: number | null;
            acreg?: string | null;
            pos?: string | null;
            credit?: number | null;
            dhd?: boolean | null;
            is_heavy?: boolean | null;
            suffix?: string | null;
            etd?: string | null;
            eta?: string | null;
            out?: string | null;
            off?: string | null;
            _on?: string | null;
            _in?: string | null;
            xed?: string | null;
            trip_tafb?: number | null;
            modified_at?: string | null;
            changed_by?: string | null;
            deleted?: boolean | null;
          }
      )
    | null;
  status: MutationStatus;
  detail?: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class APIService {
  async CreateCrewRoster(
    id?: CrewRosterID,
    input?: CrewRosterInput
  ): Promise<CreateCrewRosterMutation> {
    const statement = `mutation CreateCrewRoster($id: CrewRosterID, $input: CrewRosterInput) {
        createCrewRoster(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrewRosterMutation>response.data.createCrewRoster;
  }
  async UpdateCrewRoster(
    id?: CrewRosterID,
    input?: CrewRosterInput
  ): Promise<UpdateCrewRosterMutation> {
    const statement = `mutation UpdateCrewRoster($id: CrewRosterID, $input: CrewRosterInput) {
        updateCrewRoster(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrewRosterMutation>response.data.updateCrewRoster;
  }
  async DeleteCrewRoster(
    id?: CrewRosterID,
    operation?: DeleteOperation
  ): Promise<DeleteCrewRosterMutation> {
    const statement = `mutation DeleteCrewRoster($id: CrewRosterID, $operation: DeleteOperation) {
        deleteCrewRoster(id: $id, operation: $operation) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrewRosterMutation>response.data.deleteCrewRoster;
  }
  async CreateCrewRestriction(
    id?: CrewRestrictionID,
    input?: CrewRestrictionInput
  ): Promise<CreateCrewRestrictionMutation> {
    const statement = `mutation CreateCrewRestriction($id: CrewRestrictionID, $input: CrewRestrictionInput) {
        createCrewRestriction(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCrewRestrictionMutation>response.data.createCrewRestriction;
  }
  async UpdateCrewRestriction(
    id?: CrewRestrictionID,
    input?: CrewRestrictionInput
  ): Promise<UpdateCrewRestrictionMutation> {
    const statement = `mutation UpdateCrewRestriction($id: CrewRestrictionID, $input: CrewRestrictionInput) {
        updateCrewRestriction(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCrewRestrictionMutation>response.data.updateCrewRestriction;
  }
  async DeleteCrewRestriction(
    id?: CrewRestrictionID,
    operation?: DeleteOperation
  ): Promise<DeleteCrewRestrictionMutation> {
    const statement = `mutation DeleteCrewRestriction($id: CrewRestrictionID, $operation: DeleteOperation) {
        deleteCrewRestriction(id: $id, operation: $operation) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCrewRestrictionMutation>response.data.deleteCrewRestriction;
  }
  async CreateOpenAssignments(
    id?: OpenAssignmentsID,
    input?: OpenAssignmentsInput
  ): Promise<CreateOpenAssignmentsMutation> {
    const statement = `mutation CreateOpenAssignments($id: OpenAssignmentsID, $input: OpenAssignmentsInput) {
        createOpenAssignments(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOpenAssignmentsMutation>response.data.createOpenAssignments;
  }
  async UpdateOpenAssignments(
    id?: OpenAssignmentsID,
    input?: OpenAssignmentsInput
  ): Promise<UpdateOpenAssignmentsMutation> {
    const statement = `mutation UpdateOpenAssignments($id: OpenAssignmentsID, $input: OpenAssignmentsInput) {
        updateOpenAssignments(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOpenAssignmentsMutation>response.data.updateOpenAssignments;
  }
  async DeleteOpenAssignments(
    id?: OpenAssignmentsID,
    operation?: DeleteOperation
  ): Promise<DeleteOpenAssignmentsMutation> {
    const statement = `mutation DeleteOpenAssignments($id: OpenAssignmentsID, $operation: DeleteOperation) {
        deleteOpenAssignments(id: $id, operation: $operation) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOpenAssignmentsMutation>response.data.deleteOpenAssignments;
  }
  async CreateAssignableCrew(
    id?: AssignableCrewID,
    input?: AssignableCrewInput
  ): Promise<CreateAssignableCrewMutation> {
    const statement = `mutation CreateAssignableCrew($id: AssignableCrewID, $input: AssignableCrewInput) {
        createAssignableCrew(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssignableCrewMutation>response.data.createAssignableCrew;
  }
  async UpdateAssignableCrew(
    id?: AssignableCrewID,
    input?: AssignableCrewInput
  ): Promise<UpdateAssignableCrewMutation> {
    const statement = `mutation UpdateAssignableCrew($id: AssignableCrewID, $input: AssignableCrewInput) {
        updateAssignableCrew(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssignableCrewMutation>response.data.updateAssignableCrew;
  }
  async DeleteAssignableCrew(
    id?: AssignableCrewID,
    operation?: DeleteOperation
  ): Promise<DeleteAssignableCrewMutation> {
    const statement = `mutation DeleteAssignableCrew($id: AssignableCrewID, $operation: DeleteOperation) {
        deleteAssignableCrew(id: $id, operation: $operation) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssignableCrewMutation>response.data.deleteAssignableCrew;
  }
  async CreateCancelledFlights(
    id?: CancelledFlightsID,
    input?: CancelledFlightsInput
  ): Promise<CreateCancelledFlightsMutation> {
    const statement = `mutation CreateCancelledFlights($id: CancelledFlightsID, $input: CancelledFlightsInput) {
        createCancelledFlights(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCancelledFlightsMutation>response.data.createCancelledFlights;
  }
  async UpdateCancelledFlights(
    id?: CancelledFlightsID,
    input?: CancelledFlightsInput
  ): Promise<UpdateCancelledFlightsMutation> {
    const statement = `mutation UpdateCancelledFlights($id: CancelledFlightsID, $input: CancelledFlightsInput) {
        updateCancelledFlights(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCancelledFlightsMutation>response.data.updateCancelledFlights;
  }
  async DeleteCancelledFlights(
    id?: CancelledFlightsID,
    operation?: DeleteOperation
  ): Promise<DeleteCancelledFlightsMutation> {
    const statement = `mutation DeleteCancelledFlights($id: CancelledFlightsID, $operation: DeleteOperation) {
        deleteCancelledFlights(id: $id, operation: $operation) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCancelledFlightsMutation>response.data.deleteCancelledFlights;
  }
  async CreateOperatingFlights(
    id?: OperatingFlightsID,
    input?: OperatingFlightsInput
  ): Promise<CreateOperatingFlightsMutation> {
    const statement = `mutation CreateOperatingFlights($id: OperatingFlightsID, $input: OperatingFlightsInput) {
        createOperatingFlights(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOperatingFlightsMutation>response.data.createOperatingFlights;
  }
  async UpdateOperatingFlights(
    id?: OperatingFlightsID,
    input?: OperatingFlightsInput
  ): Promise<UpdateOperatingFlightsMutation> {
    const statement = `mutation UpdateOperatingFlights($id: OperatingFlightsID, $input: OperatingFlightsInput) {
        updateOperatingFlights(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOperatingFlightsMutation>response.data.updateOperatingFlights;
  }
  async DeleteOperatingFlights(
    id?: OperatingFlightsID,
    operation?: DeleteOperation
  ): Promise<DeleteOperatingFlightsMutation> {
    const statement = `mutation DeleteOperatingFlights($id: OperatingFlightsID, $operation: DeleteOperation) {
        deleteOperatingFlights(id: $id, operation: $operation) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOperatingFlightsMutation>response.data.deleteOperatingFlights;
  }
  async SetEbcpMaestroState(
    input?: EbcpMaestroStateInput
  ): Promise<SetEbcpMaestroStateMutation> {
    const statement = `mutation SetEbcpMaestroState($input: EbcpMaestroStateInput) {
        setEbcpMaestroState(input: $input) {
          __typename
          success
          detail
          state
          edit_state
          actioned_by
          modified_at
          data_timestamps {
            __typename
            latest_data_import
            latest_data_export
          }
          previous_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SetEbcpMaestroStateMutation>response.data.setEbcpMaestroState;
  }
  async SetEbcpMaestroEdit(
    input?: EbcpMaestroEditInput
  ): Promise<SetEbcpMaestroEditMutation> {
    const statement = `mutation SetEbcpMaestroEdit($input: EbcpMaestroEditInput) {
        setEbcpMaestroEdit(input: $input) {
          __typename
          success
          detail
          state
          edit_state
          actioned_by
          modified_at
          data_timestamps {
            __typename
            latest_data_import
            latest_data_export
          }
          previous_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SetEbcpMaestroEditMutation>response.data.setEbcpMaestroEdit;
  }
  async MaestroExport(
    input?: MaestroExportInput
  ): Promise<MaestroExportMutation> {
    const statement = `mutation MaestroExport($input: MaestroExportInput) {
        maestroExport(input: $input) {
          __typename
          StatusCode
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MaestroExportMutation>response.data.maestroExport;
  }
  async CrewRosters(input?: QueryInput): Promise<CrewRostersQuery> {
    const statement = `query CrewRosters($input: QueryInput) {
        crewRosters(input: $input) {
          __typename
          results {
            __typename
            roster_id
            restriction_id
            cmname
            cmid
            lastname
            firstname
            init
            base
            fleet
            seat
            phone
            qualtype
            report
            carrier
            flight_no
            sloc
            leg_sdate
            eloc
            leg_edate
            release
            leg_blocktime
            is_heavy
            acreg
            trip_tafb
            asmtbegin
            asmtend
            pos
            credit
            label
            nfcode
            tripno
            dhd
            suffix
            etd
            eta
            out
            off
            _on
            _in
            rca
            rfo
            xed
            seniority
            linetype
            subtype
            date
            expire
            comments
            modified_at
            changed_by
            deleted
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CrewRostersQuery>response.data.crewRosters;
  }
  async CrewRestrictions(input?: QueryInput): Promise<CrewRestrictionsQuery> {
    const statement = `query CrewRestrictions($input: QueryInput) {
        crewRestrictions(input: $input) {
          __typename
          results {
            __typename
            restriction_id
            cmname
            cmid
            lastname
            firstname
            init
            base
            fleet
            seat
            seniority
            linetype
            phone
            qualtype
            subtype
            date
            expire
            comments
            modified_at
            changed_by
            deleted
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CrewRestrictionsQuery>response.data.crewRestrictions;
  }
  async OpenAssignments(input?: QueryInput): Promise<OpenAssignmentsQuery> {
    const statement = `query OpenAssignments($input: QueryInput) {
        openAssignments(input: $input) {
          __typename
          results {
            __typename
            open_assignment_id
            asmtbegin
            asmtend
            fleet
            pos
            tripno
            report
            carrier
            sloc
            flight_no
            suffix
            leg_sdate
            eloc
            leg_edate
            release
            leg_blocktime
            is_heavy
            isdhd
            acreg
            credit
            etd
            eta
            rca
            rfo
            xed
            trip_tafb
            modified_at
            changed_by
            deleted
            status
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OpenAssignmentsQuery>response.data.openAssignments;
  }
  async AssignableCrew(input?: QueryInput): Promise<AssignableCrewQuery> {
    const statement = `query AssignableCrew($input: QueryInput) {
        assignableCrew(input: $input) {
          __typename
          results {
            __typename
            roster_id
            restriction_id
            cmname
            cmid
            base
            fleet
            seat
            asmtbegin
            asmtend
            pos
            nfcode
            qualtype
            credit
            seniority
            linetype
            phone
            modified_at
            changed_by
            deleted
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AssignableCrewQuery>response.data.assignableCrew;
  }
  async CancelledFlights(input?: QueryInput): Promise<CancelledFlightsQuery> {
    const statement = `query CancelledFlights($input: QueryInput) {
        cancelledFlights(input: $input) {
          __typename
          results {
            __typename
            cancelled_id
            flight_type
            flight_no
            carrier
            leg_sdate
            leg_edate
            leg_blocktime
            sloc
            eloc
            etd
            eta
            acreg
            fleet
            tripno
            tripdate
            crewnames
            suffix
            out
            off
            _on
            _in
            tripxld
            prevflt
            nextflt
            modified_at
            changed_by
            deleted
            status
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CancelledFlightsQuery>response.data.cancelledFlights;
  }
  async OperatingFlights(input?: QueryInput): Promise<OperatingFlightsQuery> {
    const statement = `query OperatingFlights($input: QueryInput) {
        operatingFlights(input: $input) {
          __typename
          results {
            __typename
            operating_id
            flight_type
            asmtbegin
            asmtend
            fleet
            cmname
            cmid
            tripno
            report
            carrier
            flight_no
            sloc
            leg_sdate
            eloc
            leg_edate
            release
            leg_blocktime
            rca
            rfo
            acreg
            pos
            credit
            dhd
            is_heavy
            suffix
            etd
            eta
            out
            off
            _on
            _in
            xed
            trip_tafb
            modified_at
            changed_by
            deleted
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OperatingFlightsQuery>response.data.operatingFlights;
  }
  async Audit(input?: QueryInput): Promise<AuditQuery> {
    const statement = `query Audit($input: QueryInput) {
        audit(input: $input) {
          __typename
          results {
            __typename
            id
            modified_at
            changed_by
            action
            table_name
            record_id
            changes {
              __typename
              field_name
              old_value
              new_value
            }
          }
          sortField
          sortOrder
          pageSize
          offset
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AuditQuery>response.data.audit;
  }
  async EbcpMaestroState(input?: QueryInput): Promise<EbcpMaestroStateQuery> {
    const statement = `query EbcpMaestroState($input: QueryInput) {
        ebcpMaestroState(input: $input) {
          __typename
          state
          edit_state
          current_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
          edit_state_change {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
          previous_state {
            __typename
            success
            detail
            state
            edit_state
            actioned_by
            modified_at
            data_timestamps {
              __typename
              latest_data_import
              latest_data_export
            }
            previous_state {
              __typename
              success
              detail
              state
              edit_state
              actioned_by
              modified_at
              data_timestamps {
                __typename
                latest_data_import
                latest_data_export
              }
              previous_state {
                __typename
                success
                detail
                state
                edit_state
                actioned_by
                modified_at
              }
            }
          }
          data_timestamps {
            __typename
            latest_data_import
            latest_data_export
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EbcpMaestroStateQuery>response.data.ebcpMaestroState;
  }
  OnCreatedCancelledFlightsListener(
    id?: CancelledFlightsID,
    input?: CancelledFlightsInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'OnCreatedCancelledFlights'>
    >
  > {
    const statement = `subscription OnCreatedCancelledFlights($id: CancelledFlightsID, $input: CancelledFlightsInput) {
        OnCreatedCancelledFlights(id: $id, input: $input) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'OnCreatedCancelledFlights'>
      >
    >;
  }

  OnUpdatedCancelledFlightListener(
    id: CancelledFlightsID
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'OnUpdatedCancelledFlight'>
    >
  > {
    const statement = `subscription OnUpdatedCancelledFlight($id: CancelledFlightsID!) {
        OnUpdatedCancelledFlight(id: $id) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'OnUpdatedCancelledFlight'>
      >
    >;
  }

  OnDeletedCancelledFlightListener(
    id: CancelledFlightsID
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, 'OnDeletedCancelledFlight'>
    >
  > {
    const statement = `subscription OnDeletedCancelledFlight($id: CancelledFlightsID!) {
        OnDeletedCancelledFlight(id: $id) {
          __typename
          result {
            __typename
            ... on CrewRoster {
              roster_id
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              phone
              qualtype
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              acreg
              trip_tafb
              asmtbegin
              asmtend
              pos
              credit
              label
              nfcode
              tripno
              dhd
              suffix
              etd
              eta
              out
              off
              _on
              _in
              rca
              rfo
              xed
              seniority
              linetype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on CrewRestriction {
              restriction_id
              cmname
              cmid
              lastname
              firstname
              init
              base
              fleet
              seat
              seniority
              linetype
              phone
              qualtype
              subtype
              date
              expire
              comments
              modified_at
              changed_by
              deleted
            }
            ... on OpenAssignments {
              open_assignment_id
              asmtbegin
              asmtend
              fleet
              pos
              tripdate
              tripno
              report
              carrier
              sloc
              flight_no
              suffix
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              is_heavy
              isdhd
              acreg
              credit
              etd
              eta
              rca
              rfo
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
              status
            }
            ... on AssignableCrew {
              roster_id
              restriction_id
              cmname
              cmid
              base
              fleet
              seat
              asmtbegin
              asmtend
              pos
              nfcode
              qualtype
              credit
              seniority
              linetype
              phone
              modified_at
              changed_by
              deleted
            }
            ... on CancelledFlights {
              cancelled_id
              flight_type
              flight_no
              carrier
              leg_sdate
              leg_edate
              leg_blocktime
              sloc
              eloc
              etd
              eta
              acreg
              fleet
              tripno
              tripdate
              crewnames
              suffix
              out
              off
              _on
              _in
              tripxld
              prevflt
              nextflt
              modified_at
              changed_by
              deleted
              status
            }
            ... on OperatingFlights {
              operating_id
              flight_type
              asmtbegin
              asmtend
              fleet
              cmname
              cmid
              tripno
              report
              carrier
              flight_no
              sloc
              leg_sdate
              eloc
              leg_edate
              release
              leg_blocktime
              rca
              rfo
              acreg
              pos
              credit
              dhd
              is_heavy
              suffix
              etd
              eta
              out
              off
              _on
              _in
              xed
              trip_tafb
              modified_at
              changed_by
              deleted
            }
          }
          status
          detail
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    return client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as unknown as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, 'OnDeletedCancelledFlight'>
      >
    >;
  }
}