import { Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { from, map } from 'rxjs';
import {
  EbcpMaestroApplicationState,
  EbcpMaestroStateQuery,
  EditState,
} from '../API.service';
import { QueryError, QueryResponse } from '../core';
import { BCPService } from '../services/bcp/bcp.service';

@Injectable({
  providedIn: 'root',
})
export class AppStatusService {
  bcpService = inject(BCPService);

  //TODO: it needs to adjust with BCP update
  bcpCall$ = from(this.bcpService.get({})).pipe(
    map((response: QueryResponse<EbcpMaestroStateQuery> | QueryError[]) => {
      const data: unknown = response;
      if (data) {
        const ebcpMaestroState = data as EbcpMaestroApplicationState;
        const modified_at = new Date(
          ebcpMaestroState.current_state?.modified_at ?? Date.now()
        );

        if (ebcpMaestroState.edit_state !== null) {
          this.IsEditEnabled.set(
            ebcpMaestroState.edit_state === EditState.EDIT_ON
          );
        }
        return modified_at;
      }
      return new Date();
    })
  );

  BCPState$ = from(this.bcpService.get({})).pipe(
    map((response: QueryResponse<EbcpMaestroStateQuery> | QueryError[]) => {
      const data: unknown = response;
      if (data) {
        const ebcpMaestroState = data as EbcpMaestroApplicationState;
        return ebcpMaestroState;
      }
      return null;
    })
  );

  IsUserLoggedIn = signal<boolean>(false);
  IsAdminLoggedIn = signal<boolean>(false);
  IsEditEnabled = signal<boolean | undefined>(undefined);
  IsDataRefreshEnabled = signal<boolean | undefined>(undefined);
  LastUpdate = signal<Date>(new Date());
  LastExport = signal<Date>(new Date());

  constructor() {
    this.bcpCall$.pipe(takeUntilDestroyed()).subscribe({
      next: value => {
        console.log(value);
      },
    });
  }
}
