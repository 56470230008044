import { Injectable, inject } from '@angular/core';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { generateClient } from 'aws-amplify/api';
import { environment } from '../../environments/environment';
import { QueryError } from '../core';
import {
  AssignableCrewID,
  AssignableCrewInput,
  CancelledFlightsID,
  CancelledFlightsInput,
  CrewRosterID,
  CrewRosterInput,
  DeleteOperation,
  EbcpMaestroEditInput,
  EbcpMaestroStateInput,
  OpenAssignmentsID,
  OpenAssignmentsInput,
  OperatingFlightsID,
  OperatingFlightsInput,
  QueryInput,
} from './../API.service';

export type Inputs =
  | CrewRosterInput
  | CancelledFlightsInput
  | OpenAssignmentsInput
  | AssignableCrewInput
  | OperatingFlightsInput
  | EbcpMaestroStateInput
  | EbcpMaestroEditInput;

export type IDs =
  | CrewRosterID
  | CancelledFlightsID
  | OpenAssignmentsID
  | AssignableCrewID
  | OperatingFlightsID;

import { MatSnackBar } from '@angular/material/snack-bar';
import { Amplify } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class graphqlService {
  toast = inject(MatSnackBar);
  public async executeUpdateMutationQuery<T>(
    statement: string,
    input: object,
    section?: string
  ): Promise<T | Array<QueryError>> {
    const gqlAPIServiceArguments: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    Amplify.configure({ ...environment.awsmobile });
    const client = generateClient();
    const graphqlPromise = client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

    try {
      const response = await graphqlPromise;
      if (response.errors) {
        const errors = response.errors as Array<QueryError>;
        this.displayError(errors);

        return errors;
      }

      if (section) {
        return <T>response.data[section];
      }
      return <T>response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errors = error['errors'] as Array<QueryError>;
      this.displayError(errors);
      return errors;
    }
  }

  public async executeQuery<T>(
    statement: string,
    input?: QueryInput,
    section?: string
  ): Promise<T | Array<QueryError>> {
    const gqlAPIServiceArguments: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    Amplify.configure({ ...environment.awsmobile });
    const client = generateClient();
    const graphqlPromise = client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

    try {
      const response = await graphqlPromise;

      if (response.errors) {
        const errors = response.errors as Array<QueryError>;
        this.displayError(errors);
      }

      if (section) {
        return <T>response.data[section];
      }
      return <T>response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errors = error['errors'] as Array<QueryError>;
      this.displayError(errors);
      return errors;
    }
  }

  public async executeMutation<T>(
    statement: string,
    args: { input?: Inputs; id?: IDs; operation?: DeleteOperation },
    section?: string,
    ignoreError = false // useful for long running operations that can experience AppSync timeout
  ): Promise<T | Array<QueryError>> {
    const gqlAPIServiceArguments: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    Amplify.configure({ ...environment.awsmobile });
    const client = generateClient();
    const { input, id, operation } = args;
    if (input) {
      gqlAPIServiceArguments.input = Object.fromEntries(
        Object.entries(input).filter(([, value]) => !!value)
      );
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }

    const graphqlPromise = client.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

    try {
      const response = await graphqlPromise;

      if (response.errors) return response.errors as Array<QueryError>;

      if (section) {
        if (response.data?.[section]?.['status']) {
          const message = response.data[section]['status'];
          this.displayStatusMessage(message);
        }
        return <T>response.data[section];
      }
      return <T>response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errors = error['errors'] as Array<QueryError>;
      if (!ignoreError) {
        this.displayError(errors);
      }

      return errors;
    }
  }

  private displayError(errors: Array<QueryError>) {
    if (!errors) return;
    const message = errors
      .map(
        er =>
          `❌ ERROR: ${er.message} -
          Location: ${er.locations ? JSON.stringify(er.locations) : 'n/a'}
          - Path: ${er.path ? er.path : 'n/a'}`
      )
      .join();
    this.toast.open(message, 'OK');
  }

  private displayStatusMessage(status: string) {
    const success = `SUCCESS: ${status} ✅`;
    const failure = `${status} ❌`;

    const message = status === 'FAILURE' ? failure : success;
    this.toast.open(message);
  }
}
