import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AbstractAuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
  standalone: true,
  imports: [RouterModule],
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'Maestro EBCP';
  authService = inject(AbstractAuthService);

  async ngOnInit() {
    await this.authService.handleOAuthCallback();
  }
  async ngAfterViewInit() {
    await this.authService.isAdmin();
  }
}
